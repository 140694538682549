var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discount-coupon"},[_c('div',{staticClass:"inner-content"},[_c('div',{staticClass:"create-coupon-bag"},[_c('button',{staticClass:"create-bag-button",on:{"click":_vm.createBag}},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_c('span',{staticClass:"bag-word"},[_vm._v("创建优惠券")])])]),_c('div',{staticClass:"coupon-list"},[_c('el-table',{attrs:{"data":_vm.tableData,"header-cell-style":{
          height: '60px',
          color: '#fff',
          background: '#0C0E3F',
          fontSize: '14px',
          fontWeight: '400',
          border: '1px solid transparent',
        },"cell-style":{
          height: '80px',
          color: '#fff',
          background: '#070932',
          fontSize: '14px',
          fontWeight: '400',
        }}},[_c('el-table-column',{attrs:{"prop":"bag_name","label":"商品信息","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"goods-wrapper"},[(_vm.tableData != '')?_c('div',{staticClass:"goods-cover"},[_c('img',{attrs:{"src":scope.row.goods_master_img,"alt":""}})]):_vm._e(),_c('div',{staticClass:"text-overflow-2 goods-name",attrs:{"title":scope.row.goods_name}},[_c('span',[_vm._v(_vm._s(scope.row.goods_name))]),_c('br'),_c('span',{staticStyle:{"color":"#9c9ca5"}},[_vm._v(_vm._s(scope.row.goods_sku))])])])]}}])}),_c('el-table-column',{attrs:{"prop":"coupon_name","label":"优惠券名称","width":"200"}}),_c('el-table-column',{attrs:{"prop":"coupon_type","label":"优惠券类型","width":"180"}}),_c('el-table-column',{attrs:{"prop":"grant_count","label":"发放量"}}),_c('el-table-column',{attrs:{"prop":"everyone_count","label":"每人限领"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"link-list"},[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.editCouponBag(scope.row)}}},[_vm._v("编辑")]),_c('el-link',{attrs:{"type":"danger","underline":false},on:{"click":function($event){return _vm.deleteCouponBag(scope.row)}}},[_vm._v("删除")])],1)]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }